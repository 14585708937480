import React from 'react';

export const About = () => (
  <div style={{margin: "5%", textAlign: "justify"}}> 
  <img src="/images/logo.jpg" height="300px" style={{marginLeft: "30%", marginBottom: "5%"}}></img>
    <h6>AMI is an organization of youth activists of Udaipur
      (Rajasthan -India) formed in order to build a healthy creative and
      progressive view towards life as opposed to the widely spread pessimism,
      consumerism, non-scientific way of living & declining value system.
      AMI is an organization of young social activists formed in 1996.
      Since its inception it has been actively engaged in the social development
      and socio-cultural renaissances of the tribal and non –tribal exploited
      and deprived people of southern Rajasthan through literary and various
      creative performing arts along with committed social work. <br/><br/>AMI is a group of
      committed youth who are innovative & experimental. Group is working for folk forms,
      traditional arts and rare theatre forms in tribal field of  southern Rajasthan.
      AMI has organized research work, training programs, theatre workshops and
      produced various plays along with the work for Health issues like HIV/AIDS & RCH,
      Women Empowerment especially in weaker sections of the society, Eco-friendly Environment
      activity, Consumer Awareness program and programs for promotion of Rajasthani language through
      literary event and various workshop/play for De-addition such as same.
      <br/><br/>AMI is a voluntary social Organization in the real sense.
      The leadership and the workers are not fully dependent on the organization’s finance.
      AMI is a grass root level organization and gets active support from the village people.
      It has been abstaining from the tendency of becoming a bureaucratic mammoth and shaped
      itself into a sleek situation based simple voluntary group. Support staff is non-existent
      as the workers themselves do all sorts of jobs. It is because of this
      special feature of the AMI it resides in the mind of everybody in and around Udaipur zone. 
      It has the capabilities and determination to usher in expanding socio-cultural movement. 

      <br/><br/>The fundamental philosophy of AMI is the possibility of a total change in the consciousness. 
      Thought and life of the entire society including the exploited and disadvantaged people can be 
      changed by arousing the social sensibility of every individual. This transformation of the 
      individual into a conscientious citizen will lead to the formation of a healthy and beautiful 
      nation-society full of humanity as opposed to a society ridden by inequality, exploitation, 
      sexual discrimination, consumerism, caste based oppression and inhumanity.
      World –wide study & research shows that despite many schemes and huge investment socio-economic 
      conditions have remained unchanged. Devoid of all the information inputs, science & technological 
      developments, equal socio-cultural rights and basic human rights we are still bound to live in a 
      primitive world even when the whole world is about to see the dawn of the new era.
      To break this and to provide opportunities through linguistic, cultural, theatrical & performing 
      arts and mass communication mediums to people and especially to our younger generations.
      <br/><br/>AMI is constituted so that community can establish an affinity with the mainstream of total development. 
      AMI will help people to develop their own scientific models with the help of which and along with 
      modern methods of mass communications, they will be able to creatively contribute to the 
      reconstruction of a beautiful society and fight against inequality, communalism, racism and 
      discrimination. To achieve this vision AMI set a mission of developing high socio-cultural 
      values in society, and provide better educational and health facilities and sufficient economic 
      resources, giving priorities and importance to rehabilitation of weaker section covering orphan 
      and physically challenged individuals.
    </h6>
</div>
)