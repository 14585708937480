const mainPage = [{
    src: "/images/Image 3.png",
    caption: "Image 1"
},
{
    src: "/images/Image2.jpg",
    caption: "Image 2"
},
{
    src: "/images/Image1.jpg",
    caption: "Image 3"
}]

const listPage = [{

    img: "/images/Aimg3.jpg",
    title: "Health awareness and Health Education",
    body: "HIV/AIDS & RCH sensitization to society and PRI Health checkup and medical treatment camps De-addiction and non-alcoholism workshop/ Seminar"
},
{
    img: "/images/Aimg1.jpg",
    title: "Education and Child Development Programs",
    body: "Fundamental Basic Learning with games & Education Opportunities \n for needy and deprived children especially orphan and physically challenged Facilitating Linkages (TRI) Empowering physically"
},
{
    img: "/images/Aimg2.jpeg",
    title: "Women Empowerment Program",
    body: "SJSRY activity Vocational training \nSHG Micro credit training program \nIncome generation and empowerment program"
}]

const articlePage = [{
    src: "/images/Pic1.jpg",
    caption: "Image 1"
},
{
    src: "/images/Pic2.jpg",
    caption: "Image 2"
},
{
    src: "/images/Pic3.jpg",
    caption: "Image 3"
}]

export {mainPage, listPage, articlePage}