import React from "react";

class List extends React.Component {
  render() {
    return (
      <section className="reach-marg display-none">
      <div className="container">
        <div className="spacer-map">
           <p>
            AMI promotes Sustainable Education, Health, Environment Conservation and Livelihood initiatives for deprived individuals and community, with an approach of motivating 
            the community to take on the roles and responsibilities for development of individuals who are not capable. <br/><br/>
            The key strategies are:
            <ul >
            <li>Apply principles of participation in identification and prioritization of problems and also deciding upon for identification of Development Programs.</li>
            <li>Empowerment and confidence building among physically challenged ones for normal life and participate in their main stream development.</li>
            <li>Collaborate with the government, institutions, community organizations and individuals with similar understandings and approaches.</li>
            </ul>
            <a href="/activities">Know More</a>
           </p>
          </div>
        </div>
      </section>
    );
  }
}

export default List;
